/**
 * @module ol/has
 */

const ua = typeof navigator !== 'undefined' ?
  navigator.userAgent.toLowerCase() : '';

/**
 * User agent string says we are dealing with Firefox as browser.
 * @type {boolean}
 */
export const FIREFOX = ua.indexOf('firefox') !== -1;

/**
 * User agent string says we are dealing with Safari as browser.
 * @type {boolean}
 */
export const SAFARI = ua.indexOf('safari') !== -1 && ua.indexOf('chrom') == -1;

/**
 * User agent string says we are dealing with a WebKit engine.
 * @type {boolean}
 */
export const WEBKIT = ua.indexOf('webkit') !== -1 && ua.indexOf('edge') == -1;

/**
 * User agent string says we are dealing with a Mac as platform.
 * @type {boolean}
 */
export const MAC = ua.indexOf('macintosh') !== -1;


/**
 * The ratio between physical pixels and device-independent pixels
 * (dips) on the device (`window.devicePixelRatio`).
 * @const
 * @type {number}
 * @api
 */
export const DEVICE_PIXEL_RATIO = typeof devicePixelRatio !== 'undefined' ? devicePixelRatio : 1;

/**
 * The execution context is a worker with OffscreenCanvas available.
 * @const
 * @type {boolean}
 */
export const WORKER_OFFSCREEN_CANVAS = typeof WorkerGlobalScope !== 'undefined' && typeof OffscreenCanvas !== 'undefined' &&
  self instanceof WorkerGlobalScope; //eslint-disable-line

/**
 * Image.prototype.decode() is supported.
 * @type {boolean}
 */
export const IMAGE_DECODE = typeof Image !== 'undefined' && Image.prototype.decode;

/**
 * @type {boolean}
 */
export const PASSIVE_EVENT_LISTENERS = (function() {
  let passive = false;
  try {
    const options = Object.defineProperty({}, 'passive', {
      get: function() {
        passive = true;
      }
    });

    window.addEventListener('_', null, options);
    window.removeEventListener('_', null, options);
  } catch (error) {
    // passive not supported
  }
  return passive;
})();
